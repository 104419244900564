import { Component, OnInit } from '@angular/core';
import {
  AGENDA_PLANNER_PROVIDERS,
  AgendaPlannerDisplayOptions,
  AgendaPlannerQuery,
  AgendaPlannerService,
  DateInterval,
  HourViewModel,
} from '@nexuzhealth/shared-util-agenda-planner';
import { isSameDay } from 'date-fns';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { PlannerMockApi } from './api/planner.mock-api';

@Component({
  selector: 'nxh-planner-demo',
  templateUrl: './planner-demo.component.html',
  styleUrls: ['./planner-demo.component.scss'],
  providers: [PlannerMockApi, ...AGENDA_PLANNER_PROVIDERS],
})
export class PlannerDemoComponent implements OnInit {
  plannables$: Observable<HourViewModel[]>;
  loading$: Observable<boolean>;
  day = AgendaPlannerDisplayOptions.DAY;
  plannablesObservableExample = `
  ngOnInit() {
    this.plannables$ = this.agendaPlannerQuery
      .select(s => s.selectedDateInterval)
      .pipe(
        distinctUntilChanged((prev, curr) => isSameDay(prev.from, curr.from) && isSameDay(prev.to, curr.to)),
        debounceTime(50),
        tap(() => {
          this.agendaPlannerService.setAgendaPlannerLoadingState(true);
        }),
        switchMap(selectedDateInterval => {
          return this.mockApi.listAllObjects(selectedDateInterval);
        })
      );
  }`;
  dayplannerExample = `
  <nxh-day-planner
    [allHoursToDisplayInPlanner]="plannables$"
    [template]="taskTemplate"
    emptyTitle="no routes planned for selected date."
    emptyDescription="select another date or contact your planner."
  ></nxh-day-planner>

  It is also possible to enable drag and drop (disabled by default)
  @Output() dayListChangedEmitter = new EventEmitter();
  @Input() dragDropDisabled = true;`;

  templateExample = `
  <ng-template #taskTemplate let-task="task">
  <div class="day-planner__example-obj">
    <div class="day-planner__example-obj__start-hour">{{ task.dateInterval | nxhDate: 'HH:mm' }}</div>
    <div class="day-planner__example-obj__patient-container">
      <div class="day-planner__example-obj__patient-container__profile-img">
        <nxh-avatar
          [gender]="task.data.gender"
          [circle]="true"
          [length]="33"
          [fullName]="task.data.name | formatPersonName"
        ></nxh-avatar>
      </div>
      <div class="day-planner__example-obj__patient-container__name">{{ task.data.name }}</div>
    </div>
    <div class="day-planner__example-obj__type">{{ task.content }}</div>
    <div class="day-planner__example-obj__address">{{ task.data.address }}</div>
    <div class="day-planner__example-obj__actions">
      <fa-icon icon="exclamation-circle" class="text-danger icon"></fa-icon>
      <fa-icon icon="home" class="text-warning icon"></fa-icon>
    </div>
    <button nxh-button [outline]="true" (click)="sayHello()">Bezoek openen</button>
  </div>
</ng-template>`;
  apiExample = `
...
return get<xyz>(url).pipe(
  ...
  map(v => {
    return createAgendaPlannerTaskViewModel(
      new Date(xyz),
      new Date(xyz),
      ['INSP,MED'],
      v.mockData3
    )
  })
  mergeMap(v => {
    return this.agendaPlannerQuery.groupAllTasksByHour(v);
  })
);`;

  constructor(
    private agendaPlannerService: AgendaPlannerService,
    private agendaPlannerQuery: AgendaPlannerQuery,
    private mockApi: PlannerMockApi
  ) {
    this.loading$ = agendaPlannerQuery.selectLoading();
  }

  ngOnInit() {
    this.plannables$ = this.agendaPlannerQuery
      .select((s) => s.selectedDateInterval)
      .pipe(
        distinctUntilChanged((prev, curr) => isSameDay(prev.from, curr.from) && isSameDay(prev.to, curr.to)),
        debounceTime(50),
        tap(() => {
          this.agendaPlannerService.setAgendaPlannerLoadingState(true);
        }),
        switchMap((selectedDateInterval) => {
          return this.mockApi.listAllObjects(selectedDateInterval);
        })
      );
  }

  updateDateInterval(interval: DateInterval) {
    this.agendaPlannerService.updateSelectedDateInterval(interval);
  }

  sayHello() {
    alert('hello world!');
  }
}
