import {
  ChangeDetectorRef,
  Component,
  HostBinding,
  inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional,
  Renderer2,
  SimpleChanges,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { LoadingStatesConfig } from '@nexuzhealth/shared-util';
import { BehaviorSubject, Subject, timer } from 'rxjs';
import { debounce, distinctUntilChanged, map, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'nxh-table-loader',
  templateUrl: './table-loader.component.html',
  styleUrls: ['./table-loader.component.scss'],
})
export class TableLoaderComponent implements OnInit, OnChanges, OnDestroy {
  @Input() emptyStateTemplate?: TemplateRef<unknown>;
  @Input() errorStateTemplate?: TemplateRef<unknown>;
  @Input() loading: boolean | null;
  @Input() data: unknown;
  @Input() loaded: number | null;
  @Input() error: Error | null;
  @Input() smallText = false;
  /**
   * Time between not-loading to loading. Defaults to 500. Set to 0 to disable
   */
  @Input() debounceTime = 500;
  /**
   * Time before a timeout warning kicks in.
   */
  @Input() warningTimeoutTime = -1;
  @Input() loadingMessage = '';
  @Input() hostRef = inject(ViewContainerRef).element;

  get parentEl(): HTMLElement {
    return this.hostRef.nativeElement.parentElement;
  }

  timeoutWarning = false;
  private _state = new BehaviorSubject<TableLoaderState>({
    loading: false,
    empty: false,
    error: false,
    loaded: false,
  });

  public state: TableLoaderState = {
    loading: false,
    empty: false,
    error: false,
    loaded: false,
  };

  public active$ = this._state.pipe(
    debounce((state) => (state.loading ? timer(this.debounceTime) : timer(0))),
    map((state) => state.loading || state.empty || state.error)
  );

  public loading$ = this._state.pipe(
    distinctUntilChanged(),
    map((status) => status.loading)
  );

  public isError$ = this._state.pipe(map((state) => state.error));

  private destroy$ = new Subject<void>();

  @HostBinding('class.fixed-loader')
  fixedLoader = false;

  constructor(
    private cdr: ChangeDetectorRef,
    @Optional() config: LoadingStatesConfig,
    private renderer: Renderer2 // private vcr: ViewContainerRef
  ) {
    this.warningTimeoutTime = config.warningTimeoutTime ?? -1;
  }

  @HostBinding('class.is-active')
  get isActive() {
    const state = this.state;
    return state.loading || state.empty || state.error;
  }

  @HostBinding('class.is-loading')
  get isLoading() {
    const state = this._state.getValue();
    return state.loading;
  }

  @HostBinding('class.is-error')
  get isError() {
    const state = this._state.getValue();
    return state.error;
  }

  @HostBinding('class.is-empty')
  get isEmpty() {
    const state = this._state.getValue();
    return state.empty;
  }

  ngOnInit() {
    // we show the loading state after 500ms, empty and error states are immediately shown
    this._state
      .pipe(
        debounce((state) => (state.loading ? timer(this.debounceTime) : timer(0))),
        takeUntil(this.destroy$)
      )
      .subscribe((state) => {
        this.state = state;
        this.cdr.markForCheck();
      });

    if (this.warningTimeoutTime !== -1) {
      const busy$ = new Subject<void>();
      const loading$ = this._state.pipe(map(({ loading }) => loading));

      loading$.subscribe((loading) => {
        if (loading) {
          this.renderer.addClass(this.parentEl, 'table-is-loading');
          timer(this.warningTimeoutTime)
            .pipe(takeUntil(busy$))
            .subscribe(() => {
              this.timeoutWarning = true;
              this.cdr.detectChanges();
            });
        } else {
          this.renderer.removeClass(this.parentEl, 'table-is-loading');
          this.timeoutWarning = false;
          busy$.next();
        }
      });
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    const usingInputApi = changes.loading || changes.error || changes.empty || changes.loaded;
    if (usingInputApi) {
      if (changes.loading?.currentValue != null) {
        if (changes.loading.currentValue) {
          this.startLoading();
        } else {
          this.stopLoading();
        }
      }

      if (changes.error) {
        this.setError(changes.error.currentValue);
      }

      if (
        changes.loaded?.currentValue != null ||
        (changes.data?.currentValue != null && !changes.error?.currentValue)
      ) {
        this.setLoaded(changes.loaded?.currentValue > 0 || hasData(changes.data?.currentValue) ? 1 : 0);
      }
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  startLoading() {
    this._state.next({ ...this._state.getValue(), loading: true, loaded: false });
    this.fixedLoader = (this.parentEl.getBoundingClientRect().height / window.innerHeight) * 100 > 80;
  }

  stopLoading() {
    this._state.next({ ...this._state.getValue(), loading: false, loaded: false });
  }

  setError(err: Error) {
    if (err) {
      this.renderer.addClass(this.parentEl, 'table-errorstate');
      this._state.next({ ...this._state.getValue(), loading: false, error: true });
    } else {
      this.renderer.removeClass(this.parentEl, 'table-errorstate');
      this._state.next({ ...this._state.getValue(), error: false });
    }
  }

  setLoaded(length: number) {
    const empty = length === 0;
    this.renderer.removeClass(this.parentEl, 'table-errorstate');
    this._state.next({ loading: false, error: false, empty, loaded: !empty });
  }
}

function hasData(data: any) {
  if (Array.isArray(data)) {
    return data.length > 0;
  }
  return data != null;
}

interface TableLoaderState {
  loading: boolean;
  empty: boolean;
  error: boolean;
  loaded: boolean;
}
