import { Component, inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanDeactivateFn, RouterStateSnapshot } from '@angular/router';
import { FormQuery, FormStore } from '@nexuzhealth/shared-ui-toolkit/dirty-check/data-access';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { I18NextPipe } from 'angular-i18next';
import { tap } from 'rxjs/operators';
import { from, Observable } from 'rxjs';
import { FormExitPopupComponent } from '../components/form-exit-popup/form-exit-popup.component';

/**
 * You need to manually add this guard to each route you want to be protected in the routing module.
 * Example:
 * ```js
 * const routes = [{
 *   path: '/form',
 *   component: FormComponent
 * }]
 * ```
 * becomes
 *```js
 * const routes = [{
 *   path: '/form',
 *   component: FormComponent,
 *   canDeactivate: [CanDeactivateGuard]
 * }]
 * ```
 */
export const CanDeactivateGuard: CanDeactivateFn<Component> = (
  component: Component,
  currentRoute: ActivatedRouteSnapshot,
  currentState: RouterStateSnapshot,
  nextState: RouterStateSnapshot
): boolean | Observable<boolean> => {
  const modalService = inject(NgbModal);
  const formStore = inject(FormStore);
  const outlet = currentRoute.data?.canDeactivateOutlet ?? 'primary';

  if (isDirty(outlet)) {
    // if there is an open modal, we check whether we can continue through a native dialog
    if (modalService.hasOpenModals()) {
      if (getConfirmation()) {
        cleanupFormStore(outlet, formStore);
        // also, before navigating away, we close all modals
        modalService.dismissAll();
        return true;
      } else {
        return false;
      }
    } else {
      return from(modalService.open(FormExitPopupComponent, { backdrop: false, animation: false }).result).pipe(
        tap((leave) => {
          if (leave) {
            cleanupFormStore(outlet, formStore);
          }
        })
      );
    }
  }

  // closing all modals when we move pages
  if (modalService.hasOpenModals()) {
    modalService.dismissAll();
  }

  cleanupFormStore(outlet, formStore);
  return true;
};

function getConfirmation() {
  return confirm(inject(I18NextPipe).transform('_exit-form.popup-title'));
}

function isDirty(outlet: string) {
  const formQuery = inject(FormQuery);
  if (outlet !== 'primary') {
    return formQuery.isOutletDirty(outlet);
  } else {
    return formQuery.isOutletDirty('primary') || formQuery.isModalDirty();
  }
}

function cleanupFormStore(outlet: string, formStore: FormStore) {
  formStore.destroyOutlet(outlet || 'primary');
}
