import { Component } from '@angular/core';
import { ScrollspyService } from '@nexuzhealth/shared-ui-toolkit/scrollspy';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'nxh-dropdown-demo',
  templateUrl: './dropdown-demo.component.html',
  styleUrls: ['./dropdown-demo.component.scss'],
})
export class DropdownDemoComponent {
  showExampleBlock1 = true;
  block1Code = `
  <div ngbDropdown class="hide-default-down-icon">
    <button nxh-button [outline]="true" ngbDropdownToggle>Dropdown <fa-icon icon="chevron-down" class="chevron"></fa-icon></button>
    <div class="dropdownmenu" ngbDropdownMenu>
      <button class="btn" ngbDropdownItem>
        <fa-icon icon="pills"></fa-icon> <span>Default Action</span>
      </button>
      <button class="btn dropdown-item-primary" ngbDropdownItem>
        <fa-icon icon="ruler"></fa-icon> <span>Primary Action</span>
      </button>
      <button class="btn dropdown-item-success" ngbDropdownItem>
        <fa-icon icon="heart-rate"></fa-icon> <span>Success Action</span>
      </button>
      <button class="btn dropdown-item-warning" ngbDropdownItem>
        <fa-icon icon="heart-rate"></fa-icon> <span>Warning Action</span>
      </button>
      <button class="btn dropdown-item-danger" ngbDropdownItem>
        <fa-icon icon="trash-alt"></fa-icon> <span>Danger action</span>
      </button>
    </div>
  </div>
  `;

  showExampleBlock3 = true;
  block3Code = `
  <div ngbDropdown class="hide-default-down-icon">
    <button nxh-button [outline]="true" ngbDropdownToggle>Dropdown with headers <fa-icon icon="chevron-down" class="chevron"></fa-icon></button>
    <div class="dropdownmenu" ngbDropdownMenu>
      <label class="dropdown-header">Test label hier</label>
      <button class="btn dropdown-item-primary" ngbDropdownItem (click)="doSomething()">
        <fa-icon icon="plus"></fa-icon> <span>Default Action</span>
      </button>
      <label class="dropdown-header">Dit is een ander label</label>
      <button class="btn dropdown-item-primary" ngbDropdownItem (click)="doSomething()">
        <fa-icon icon="ruler"></fa-icon> <span>Primary Action</span>
      </button>
      <button class="btn dropdown-item-danger" ngbDropdownItem (click)="doSomething()">
        <fa-icon icon="trash-alt"></fa-icon> <span>Danger action</span>
      </button>
      <label class="dropdown-header">Dit is nog een label</label>
      <button class="btn" ngbDropdownItem (click)="doSomething()">
        <nxh-bullet status="active">Hoge bloeddruk</nxh-bullet>
      </button>
      <button class="btn" ngbDropdownItem (click)="doSomething()">
        <nxh-bullet status="danger">Hoge bloeddruk</nxh-bullet>
      </button>
      <hr>
      <button class="btn" ngbDropdownItem (click)="doSomething()">
        <span class="text-primary">Nieuw zorgelement toevoegen</span>
      </button>
    </div>
  </div>
  `;

  showExampleBlock4 = true;
  block4Code = `
  <div ngbDropdown class="hide-default-down-icon">
    <button nxh-button [outline]="true" ngbDropdownToggle>Dropdown item inner scroll <fa-icon icon="chevron-down" class="chevron"></fa-icon></button>
    <div class="dropdownmenu" ngbDropdownMenu>
      // wrap your content in 'dropdown-container--limited'
      <div class="dropdown-container--limited">
        <label class="dropdown-header">Test label hier</label>
        <button class="btn dropdown-item-primary" ngbDropdownItem (click)="doSomething()">
          <fa-icon icon="plus"></fa-icon> <span>Default Action</span>
        </button>
        <label class="dropdown-header">Dit is een ander label</label>
        <button class="btn dropdown-item-success" ngbDropdownItem (click)="doSomething()">
          <fa-icon icon="ruler"></fa-icon> <span>Success Action</span>
        </button>
        <button class="btn dropdown-item-danger" ngbDropdownItem (click)="doSomething()">
          <fa-icon icon="trash-alt"></fa-icon> <span>Danger action</span>
        </button>
        <label class="dropdown-header">Dit is nog een label</label>
        <button class="btn" ngbDropdownItem (click)="doSomething()">
          <nxh-bullet status="active">Hoge bloeddruk</nxh-bullet>
        </button>
        ...
      </div>
      <hr>
      <button class="btn dropdown-item-primary" ngbDropdownItem (click)="doSomething()">
        <fa-icon icon="plus"></fa-icon> <span>Voeg hier iets toe</span>
      </button>
      <button class="btn dropdown-item-primary" ngbDropdownItem (click)="doSomething()">
        <fa-icon icon="ruler"></fa-icon> <span>Klik op deze button</span>
      </button>
      <button class="btn dropdown-item-primary" ngbDropdownItem (click)="doSomething()">
        <fa-icon icon="plus"></fa-icon> <span>Doe nog iets anders</span>
      </button>
    </div>
  </div>
  `;

  selected$ = new BehaviorSubject('block1');

  constructor(private scrollspyService: ScrollspyService, private toastr: ToastrService) {}

  scrollTo(id) {
    this.selected$.next(id);
    this.scrollspyService.scrollTo(id, { emitEvent: false });
  }

  scrolledInView(id) {
    this.selected$.next(id);
  }

  doSomething() {
    this.toastr.success('Piep');
  }
}
