<div style="position: initial">
  <div
    ngbDropdown
    #dropdown="ngbDropdown"
    [container]="container"
    class="hide-default-down-icon"
    [ngClass]="dropdown.isOpen() ? 'ellipsis' : ''"
    (openChange)="dropdownChangedEvent($event)"
    (click)="$event.stopPropagation()"
  >
    <button
      nxh-button
      buttonType="icon"
      (click)="showDropdown = false"
      [ngbTooltip]="'more-actions' | i18next"
      nxhDataTest="dropdownConfig"
      ngbDropdownToggle
    >
      <fa-icon icon="ellipsis-h"></fa-icon>
    </button>
    <div ngbDropdownMenu aria-labelledby="dropdownConfig" class="list-item__dropdown">
      <div *ngIf="!showDropdown">
        <ng-content select="[ellipsisFirst]"></ng-content>
        <button *ngIf="editOption" ngbDropdownItem (click)="dropdown.close(); edit()">
          <fa-icon icon="pen"></fa-icon>
          <div>{{ 'edit' | i18next }}</div>
        </button>
        <button *ngIf="editLink" ngbDropdownItem [routerLink]="editLink" (click)="dropdown.close()">
          <fa-icon icon="pen"></fa-icon>
          <div>{{ 'edit' | i18next }}</div>
        </button>
        <ng-content select="[ellipsisAfterEdit]"></ng-content>
        <button
          *ngIf="deleteOption"
          ngbDropdownItem
          [nxhConfirm]
          #nxhConfirm
          [confirmText]="'confirm-delete%item' | i18next : { item: placeholder | i18next }"
          [busy]="loading"
          (confirm)="delete()"
          [bypassConfirm]="bypassConfirm"
        >
          <fa-icon icon="trash-alt" class="text-danger"></fa-icon>
          <div>{{ 'delete' | i18next }}</div>
        </button>
        <ng-content></ng-content>
      </div>
    </div>
  </div>
</div>
