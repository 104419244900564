import {
  ChangeDetectorRef,
  Directive,
  ElementRef,
  Inject,
  InjectionToken,
  Input,
  OnInit,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { ResourceName } from '@nexuzhealth/shared-domain';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

@Directive({
  // eslint-disable-next-line
  selector: '[featureFlag]',
})
export class FeatureFlagDirective implements OnInit {
  @Input() featureFlag: string;
  @Input() featureFlagElse: TemplateRef<any>;

  constructor(
    private el: ElementRef,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    @Inject(FEATURE_FLAG_QUERY) private featureFlagQuery: FeatureFlagQuery,
    @Inject(FEATURE_FLAG_DATASOURCE) private featureFlagDatasource: FeatureFlagDatasource,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    if (!this.featureFlag) {
      this.viewContainer.createEmbeddedView(this.templateRef);
      return;
    }

    this.featureFlagDatasource.load(this.featureFlag).subscribe();

    this.featureFlagQuery
      .selectFeatureFlag(this.featureFlag)
      .pipe(take(1))
      .subscribe((featureFlag) => {
        if (featureFlag.enabled) {
          this.viewContainer.createEmbeddedView(this.templateRef);
        } else if (this.featureFlagElse) {
          this.viewContainer.createEmbeddedView(this.featureFlagElse);
        } else {
          this.viewContainer.clear();
        }
        this.cdr.markForCheck();
      });
  }
}

export const FEATURE_FLAG_QUERY = new InjectionToken('feature-flag-query');
export const FEATURE_FLAG_DATASOURCE = new InjectionToken('feature-flag-datasource');

export interface FeatureFlagQuery {
  selectFeatureFlag(featureFlag: ResourceName): Observable<{ enabled: boolean }>;
}

export interface FeatureFlagDatasource {
  load(featureFlag: ResourceName): Observable<any>;
}
