import { format } from 'date-fns';
import { PatientAddress } from '@nexuzhealth/shared-domain';
import { PatientResource } from '@nexuzhealth/shared-resourceplanning-domain';
import { ToolkitTypeValue } from '@nexuzhealth/shared-ui-toolkit/pill';
import { convertDurationToHeight } from '../services/date-util.service';

export interface DateInterval {
  from: Date;
  to: Date;
}

export interface HourViewModel {
  hour: number;
  formattedHour: string;
  tasks?: AgendaPlannerTaskViewModel[];
}

export interface DayViewModel {
  dayName: string;
  monthDayNumber: string;
  date: Date;
  isToday: boolean;
  tasks?: AgendaPlannerTaskViewModel[];
}

export interface AgendaPlannerTaskViewModel {
  dateInterval: DateInterval;
  timeIntervalString: string;
  taskDurationInHeight: number;
  content?: string[];
  data?: {
    careCodeErrors: any[];
    palliative: boolean;
    unavailable: boolean;
    careAddress: PatientAddress | undefined;
    patientRefName: string;
    finished: boolean;
    unavailableError: any;
    patientError: any;
    contactError: any;
    carePlanForfaitError: string;
    hygienicCareStatus: ToolkitTypeValue;
    katzScore: string | undefined;
    careCodes: any[];
    cancelled: boolean;
    fullPatientName: PatientResource;
    diabetes: boolean;
  };
}

//Properties to be determined
export interface Plannable {
  dateInterval: DateInterval;
  content: string;
}

export function createAgendaPlannerTaskViewModel(
  dateFrom: Date,
  dateTo: Date,
  content = [],
  data = null
): AgendaPlannerTaskViewModel {
  const formatString = 'HH:mm';
  return {
    dateInterval: { from: dateFrom, to: dateTo },
    timeIntervalString: `${format(dateFrom, formatString)} - ${format(dateTo, formatString)}`,
    content,
    taskDurationInHeight: convertDurationToHeight(dateFrom, dateTo),
    data,
  };
}
