import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  Optional,
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { DayPartOption } from '@nexuzhealth/shared-domain';
import { FormHelperDirective } from '@nexuzhealth/shared-ui-toolkit/forms';

@Component({
  selector: 'nxh-dayparts',
  templateUrl: './dayparts.component.html',
  styleUrls: ['./dayparts.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DaypartsComponent implements OnInit, OnDestroy {
  @Input() parent!: UntypedFormGroup;
  @Input() title = 'time';
  @Input() partOptions: DayPartOption[] = [];
  @Input() useDayPartAllDay = true;
  @Input() validateDayPartOrTime = true;
  private destroy$$ = new Subject<void>();

  constructor(private cdr: ChangeDetectorRef, @Optional() private fhd?: FormHelperDirective) {}

  private get partsFormGroup() {
    return this.parent.get('parts') as UntypedFormGroup;
  }

  ngOnInit(): void {
    const allDayFormControl = this.parent.get('allDayOrTime') as UntypedFormControl;
    allDayFormControl?.setValue(!this.parent.get('parts').value || this.parent.get('parts').value?.length === 0);

    // Set value of radio to day parts when day part changes
    this.partsFormGroup?.valueChanges.pipe(takeUntil(this.destroy$$)).subscribe((parts) => {
      if (parts?.length > 0) {
        allDayFormControl?.setValue(false);
      }
    });

    if (this.useDayPartAllDay) {
      // Reset day parts when allDay is selected
      allDayFormControl?.valueChanges.pipe(takeUntil(this.destroy$$)).subscribe((allDay) => {
        if (allDay) {
          this.partsFormGroup?.reset([]);
        }
      });
    } else {
      const timeslotFormControl = this.parent.get('time') as UntypedFormControl;

      // Set the value of the radio to timeslot when its value changes
      timeslotFormControl?.valueChanges.pipe(distinctUntilChanged(), takeUntil(this.destroy$$)).subscribe((time) => {
        if (time) {
          allDayFormControl.setValue(true);
        }
      });
      // Reset day parts when timeslot is selected
      // Reset timeslot when day parts is selected
      // Use distinctUntilChanged because updateValueAndValidity will cause timeslotFormControl.valueChanges to trigger creating an endless loop and resulting in console error
      allDayFormControl?.valueChanges.pipe(distinctUntilChanged(), takeUntil(this.destroy$$)).subscribe((allDay) => {
        if (allDay) {
          this.partsFormGroup?.reset([]);
          if (this.validateDayPartOrTime) {
            timeslotFormControl.addValidators(Validators.required);
          }
        } else {
          timeslotFormControl.reset();
          if (this.validateDayPartOrTime) {
            timeslotFormControl.clearValidators();
          }
        }
        timeslotFormControl.updateValueAndValidity({ onlySelf: true });
      });
    }

    // workaround to show error message on toggling allDay, cf. https://github.com/Ninja-Squad/ngx-valdemort/issues/317
    this.parent.statusChanges.pipe(takeUntil(this.destroy$$)).subscribe(() => {
      this.parent.markAsTouched();
    });

    // because we cannot use val-errors (cf. comment in html) we need to refresh the custom validation component on
    // submit
    this.fhd?.submitButtonClicked$.subscribe(() => this.cdr.markForCheck());
  }

  ngOnDestroy(): void {
    this.destroy$$.next();
    this.destroy$$.complete();
  }
}
