import { ActivatedRoute, Params, Router, UrlCreationOptions } from '@angular/router';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export function withParentParams(activatedRoute: ActivatedRoute): Observable<Params> {
  let currentRoute = activatedRoute;
  const routeTree = [currentRoute];
  while (currentRoute.parent) {
    routeTree.push(currentRoute.parent);
    currentRoute = currentRoute.parent;
  }

  return combineLatest(routeTree.map((route) => route.params)).pipe(
    map((paramsList) => {
      return paramsList.reduce((acc: { [key: string]: unknown }, next) => {
        Object.assign(acc, next);
        return acc;
      }, {});
    })
  );
}

/**
 * Navigates to given url segments in new tab,
 * cf. https://savaniabhishek.medium.com/open-new-route-in-a-new-browser-tab-in-angular-2-5d98ae79b2ab
 * and https://stackoverflow.com/questions/41531244/how-to-open-a-new-tab-with-router-navigate-in-typescript
 */
export function navigateInNewTab(router: Router, commands: any[], navigationExtras?: UrlCreationOptions) {
  const urlTree = router.createUrlTree(commands, navigationExtras || {});
  const url = router.serializeUrl(urlTree);
  // noopener for security reasons, cf. https://stackoverflow.com/questions/40593632/use-window-open-but-block-use-of-window-opener/40593743#40593743
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
  if (newWindow) {
    // for older browsers
    newWindow.opener = null;
  }
}

export function navigate(router: Router, newTab: boolean, commands: any[], navigationExtras?: UrlCreationOptions) {
  if (newTab) {
    navigateInNewTab(router, commands, navigationExtras);
  } else {
    router.navigate(commands, navigationExtras);
  }
}
