import {Directive, ElementRef, Input, OnDestroy, OnInit, Optional, Renderer2} from '@angular/core';
import {FormGroupDirective, NgControl} from '@angular/forms';
import {FormHelperDirective} from '@nexuzhealth/shared-ui-toolkit/forms';
import {I18NextPipe} from 'angular-i18next';
import {merge, of, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {AutoValidateDirective} from './auto-validate.directive';
import {BypassAutoValidateDirective} from './bypass-auto-validate.directive';
import {DeprecatedAutoValidateDirective} from './deprecated-auto-validate.directive';

/**
 * @deprecated Use a form with nxhAutoValidate in stead
 */
// cf. inspired by: https://netbasal.com/make-your-angular-forms-error-messages-magically-appear-1e32350b7fa5
@Directive({
  selector:
    'input[formControlName],select[formControlName], textarea[formControlName], nxh-select[formControlName], [nxhControlError],[formControl]',
})
export class ControlErrorDirective implements OnInit, OnDestroy {
  // This member allows for setting the prefix of error messages. When empty the default prefix 'common:errors.' is
  // used. When a value is present, the suffix of the error message is first appended to errorLabelPrefix. If there's a
  // translated value, this is value is used, otherwise, the default prefix is used for translation.
  // Example: <input nxhControlError="contact__family--errors" required>
  // when the field is not entered, this directive will try to resolve the label "contact__family--errors-required" from
  // Locize. If not found the default "common:errors.required" will be used.
  @Input('nxhControlError') errorLabelPrefix: string;
  @Input() error: string;
  @Input() label: string;
  @Input() errorMap: { [errorName: string]: string } = {};

  private errorDiv: HTMLDivElement;
  private destroy$ = new Subject<void>();

  constructor(
    private el: ElementRef,
    private control: NgControl,
    private i18n: I18NextPipe,
    private renderer: Renderer2,
    @Optional() private parentForm: FormGroupDirective,
    @Optional() private autoValidateDirective: AutoValidateDirective,
    @Optional() private bypassAutoValidateDirective: BypassAutoValidateDirective,
    @Optional() private formHelperDirective: FormHelperDirective,
    @Optional() private deprecatedAutoValidateDirective: DeprecatedAutoValidateDirective
  ) {}

  ngOnInit(): void {
    if (
      !this.parentForm ||
      this.autoValidateDirective ||
      this.bypassAutoValidateDirective ||
      this.formHelperDirective ||
      !this.deprecatedAutoValidateDirective
    ) {
      return; // this one is using the new one!!!
    }

    const parentFormSubmit$ = this.parentForm ? this.parentForm.ngSubmit : of();
    merge<[any, any]>(parentFormSubmit$, this.control.valueChanges)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        const controlErrors = this.control.errors;
        if (controlErrors) {
          const errorName = Object.keys(controlErrors)[0];

          let translated: string;

          if (this.errorMap && this.errorMap[errorName]) {
            translated = this.translate(this.errorMap[errorName]);
          } else if (this.errorLabelPrefix) {
            //Temporary fix until date picker component is refactored
            if (this.errorLabelPrefix === 'no-error') {
              return;
            }

            const namespacePos = this.errorLabelPrefix.indexOf(':');
            let label = `${this.errorLabelPrefix}`;
            translated = this.translate(label, controlErrors[errorName]);
            if (namespacePos >= 0) {
              label = label.substring(namespacePos + 1);
            }
          } else {
            // should be renamed to _errors
            translated = this.translate(`common:errors.${errorName}`, controlErrors[errorName]);
          }
          this.setError(translated);
        } else {
          this.setError(null);
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  private translate(key, options?) {
    return this.i18n.transform(key, { ...options, label: this.label });
  }

  private setError(text) {
    if (text) {
      if (!this.errorDiv) {
        this.errorDiv = this.createErrorDiv();
      }
      this.renderer.setProperty(this.errorDiv, 'innerText', text);
    } else {
      if (this.errorDiv) {
        const parent = this.renderer.parentNode(this.errorDiv);
        this.renderer.removeChild(parent, this.errorDiv);
        this.renderer.removeClass(parent, 'has-error-message');
        this.errorDiv = null;
      }
    }
  }

  private createErrorDiv() {
    const input: HTMLInputElement = this.el.nativeElement;
    const parent = this.getParent(input);

    const errorDiv = this.renderer.createElement('div');
    const classList = ['input-error'];
    classList.map((cssClass) => this.renderer.addClass(errorDiv, cssClass));
    this.renderer.addClass(parent, 'has-error-message');
    if (!this.hasError(parent)) {
      this.renderer.appendChild(parent, errorDiv);
    }
    return errorDiv;
  }

  private hasError(el: HTMLElement) {
    let hasError = false;
    el.childNodes.forEach((element: HTMLElement) => {
      if (element.classList && element.classList.contains('input-error')) {
        hasError = true;
      }
    });
    return hasError;
  }

  private getParent(el) {
    const parent: HTMLElement = this.renderer.parentNode(el);
    const parentIsInputGroup = parent.classList.contains('input-group');
    return parentIsInputGroup ? this.renderer.parentNode(parent) : parent;
  }
}
