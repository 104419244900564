import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { AccessRole, Error, OrganisationAccessRole, UserContext } from '@nexuzhealth/shared-domain';
import { filterNullOrUndefined } from '@nexuzhealth/shared-util';
import { combineLatest, Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { AuthState, AuthStore } from './auth-store.service';

@Injectable({
  providedIn: 'root',
})
export class AuthQuery extends Query<AuthState> {
  error$ = this.selectError<Error>();

  private userRoles$ = this.selectUser().pipe(map((user) => user.accessRoles));

  allRoles$: Observable<(AccessRole | OrganisationAccessRole)[]> = combineLatest([
    this.userRoles$,
    this.selectOrganisationAccessRoles(),
  ]).pipe(map(([userRoles, orgRoles]) => [...userRoles, ...orgRoles]));

  constructor(protected store: AuthStore) {
    super(store);
  }

  /**
   * Get the current user
   */
  selectUser() {
    return this.select().pipe(
      map((value) => value.user),
      filterNullOrUndefined
    );
  }

  getUser() {
    return this.getValue().user;
  }

  selectUserName() {
    return this.select().pipe(
      map((value) => value.user?.name),
      filterNullOrUndefined
    );
  }

  getUserName() {
    return this.getUser().name;
  }

  selectUserContextName() {
    return this.select().pipe(
      map((value) => value.userContextName),
      distinctUntilChanged()
    );
  }

  getUserContextName() {
    return this.getValue().userContextName;
  }

  selectUserContext(): Observable<UserContext> {
    return combineLatest([
      this.selectUserContextName().pipe(filterNullOrUndefined),
      this.selectUserContexts().pipe(filterNullOrUndefined),
    ]).pipe(map(([name, contexts]) => contexts.find((userContext) => userContext.name === name)));
  }

  getUserContext(): UserContext {
    const currentState = this.getValue();
    return currentState.userContexts?.find((userContext) => userContext.name === currentState.userContextName);
  }

  getOrganisationName(): string {
    const userContext = this.getUserContext();
    return userContext?.tenant?.organisationName;
  }

  selectOrganisationName() {
    return this.selectUserContext().pipe(map((context) => context?.tenant?.organisationName));
  }

  selectUserContexts() {
    return this.select().pipe(
      map((value) => value.userContexts),
      distinctUntilChanged()
    );
  }

  getOrganisationAccessRoles() {
    return this.getValue().organisationAccessRoles;
  }

  getAllRoles() {
    return [...this.getOrganisationAccessRoles(), ...this.getUser().accessRoles];
  }

  selectOrganisationAccessRoles() {
    return this.select().pipe(
      map((value) => value.organisationAccessRoles),
      distinctUntilChanged()
    );
  }
}
