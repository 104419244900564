import { PersonName } from '@nexuzhealth/shared-domain';

export type PersonNameOrder = 'given-family' | 'family-given';

export function formatPersonName(
  personName: PersonName | undefined | null,
  personNameOrder: PersonNameOrder = 'family-given'
): string {
  return personName
    ? (personNameOrder === 'given-family'
        ? [personName.prefix, personName.given, personName.family]
        : [personName.prefix, personName.family, personName.given]
      )
        .filter((i) => !!i)
        .join(' ')
    : '';
}

export function generateInitialsFromName(
  name: PersonName | string | undefined,
  personNameOrder: PersonNameOrder = 'given-family'
): string {
  if (!name) {
    return '';
  }

  let family: string;
  let given: string;

  if (typeof name === 'string') {
    // assuming (for now?) the given string is in format given + family
    const initialsRegex = name.match(/\b\w/g) || [];
    given = initialsRegex.shift() || '';
    family = initialsRegex.pop() || '';
  } else {
    given = name.given?.[0];
    family = name.family?.[0];
  }

  return (personNameOrder === 'given-family' ? [given, family] : [family, given])
    .filter((part) => !!part)
    .join('')
    .toUpperCase();
}
