import { Injectable } from '@angular/core';
import { filterNilValue, QueryEntity } from '@datorama/akita';
import { ResourceName } from '@nexuzhealth/shared-domain';
import { filter, map } from 'rxjs/operators';
import { FeatureFlagState, FeatureFlagStore } from './feature-flag.store';

@Injectable({ providedIn: 'root' })
export class FeatureFlagQuery extends QueryEntity<FeatureFlagState> {
  selectFeatureFlag(flagName: ResourceName) {
    return this.selectEntity(flagName).pipe(
      filterNilValue(),
      filter(({ loading }) => !loading)
    );
  }

  selectFeatureFlagEnabled(flagName: ResourceName) {
    return this.selectFeatureFlag(flagName).pipe(map(({ enabled }) => enabled));
  }

  constructor(protected store: FeatureFlagStore) {
    super(store);
  }
}
