import { AfterViewInit, Component, ElementRef, forwardRef, Input, OnDestroy, Optional, ViewChild } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import { addDataTestAttributes, DataTestDirective } from '@nexuzhealth/shared-tech-feature-e2e';
import { Focusable, FOCUSSABLE } from '@nexuzhealth/shared-ui-toolkit/focus';
import { ControlComponent } from '@nexuzhealth/shared-ui-toolkit/forms';
import { fromEvent, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'nxh-number-field',
  templateUrl: './number-field.component.html',
  styleUrls: ['./number-field.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NumberFieldComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => NumberFieldComponent),
      multi: true,
    },
    { provide: FOCUSSABLE, useExisting: forwardRef(() => NumberFieldComponent) },
  ],
})
export class NumberFieldComponent implements ControlValueAccessor, Validator, Focusable, AfterViewInit, OnDestroy {
  @Input() prefix?: string;
  @Input() suffix?: string;
  @Input() step: number | null = null;
  @Input() maxPrecision: number | null = null;
  @Input() placeholder: string | null = null;
  @Input() min: number | null = null;
  @Input() max: number | null = null;
  @Input() alignment: 'right' | 'left' = 'left';
  @Input() roundingPrecision: number | null = null;

  /**
   * @deprecated Use <nh-control>
   */
  @Input() errorMessage: string;
  /**
   * @deprecated Use <nh-control>
   */
  @Input() label: string;
  /**
   * @deprecated Use <nh-control>
   */
  @Input() required = false;
  /**
   * @deprecated Use <nh-control>
   */
  @Input() errorMap: any;

  @ViewChild('input') elementRef: ElementRef<HTMLInputElement>;
  @ViewChild('inputCC') elementRefCC: ElementRef<HTMLInputElement>;

  value: string | number | null;
  disabled: boolean;

  onChange: (_: number | null) => void;
  onTouch: () => void;

  private destroy$$ = new Subject<void>();

  constructor(
    private element: ElementRef,
    @Optional() private dataTestDirective?: DataTestDirective,
    @Optional() public controlComponent?: ControlComponent
  ) {}

  ngAfterViewInit(): void {
    addDataTestAttributes(this.dataTestDirective?.nxhDataTest || 'number-field-component', {
      element: this.controlComponent ? this.elementRefCC.nativeElement : this.elementRef.nativeElement,
      suffix: '_numberfield',
    });

    // This will remove the wheel event listener on the number fields.
    fromEvent(this.element.nativeElement, 'wheel', { capture: true })
      .pipe(takeUntil(this.destroy$$))
      .subscribe((event: WheelEvent) => {
        event.preventDefault();
      });
  }

  ngOnDestroy() {
    this.destroy$$.next();
    this.destroy$$.complete();
  }

  writeValue(val: string | number | null) {
    let valueToWrite = null;
    if (val !== null && val !== undefined && val !== '' && !isNaN(Number(val))) {
      valueToWrite =
        this.roundingPrecision === null
          ? Number(val).toString()
          : this.calculatePrecision(Number(val), this.roundingPrecision).toFixed(this.roundingPrecision);
    }
    this.value = valueToWrite;
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  validate(control: AbstractControl) {
    return this.validateNumber(control.value);
  }

  setFocus() {
    if (this.controlComponent) {
      this.elementRefCC.nativeElement.focus();
    } else {
      this.elementRef.nativeElement.focus();
    }
  }

  onInput(event) {
    const targetValue: string = event.target.value;
    const valueAsNumber: number | null = targetValue === '' ? null : Number(targetValue);
    this.onChange(
      valueAsNumber === null
        ? null
        : this.roundingPrecision === null
        ? valueAsNumber
        : this.calculatePrecision(valueAsNumber, this.roundingPrecision)
    );
  }

  onBlur(event) {
    const targetValue: string = event.target.value;
    const valueAsNumber: number | null = targetValue === '' ? null : Number(targetValue);

    if (valueAsNumber !== null && this.roundingPrecision !== null) {
      event.target.value = this.calculatePrecision(valueAsNumber, this.roundingPrecision).toFixed(
        this.roundingPrecision
      );
    }

    this.onTouch();
  }

  // public for testing purposes
  public validateNumber(value: string): ValidationErrors | null {
    if (value === '' || value === null || value === undefined) {
      return null;
    }

    return this._validateNumber(value) || this.validatePrecision(value) || this.validateMinMax(value) || null;
  }

  private _validateNumber(value: string) {
    // need config to allow/disallow scientific notation is ok
    if (isNaN(+value)) {
      return { 'invalid-number': true };
    }

    return null;
  }

  private validatePrecision(value: string) {
    if (this.maxPrecision === 0 && !isValidPrecision(value, 0)) {
      return { 'precision-error_round': true };
    }

    if (this.maxPrecision > 0 && !isValidPrecision(value, this.maxPrecision)) {
      return { 'precision-error_max': { maxPrecision: this.maxPrecision } };
    }

    return null;
  }

  // do we need min-max validators?
  private validateMinMax(value: string) {
    if (this.max !== null && +value > this.max) {
      return { max: { max: this.max } };
    }

    if (this.min !== null && +value < this.min) {
      return { min: { min: this.min } };
    }

    return null;
  }

  private calculatePrecision(value: number, precision: number): number {
    const mathRoundModifier = Math.pow(10, precision);
    return Math.round((value + Number.EPSILON) * mathRoundModifier) / mathRoundModifier;
  }
}

function isValidPrecision(value: string, precision: number) {
  const regex = precision === 0 ? /^-?\d+$/ : new RegExp('^-?\\d*[,.]?\\d{0,' + precision + '}?$');
  return regex.test(value);
}
