import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  Address,
  HealthCareProfession,
  ListType,
  PagingResult,
  Practitioner,
  PractitionerContext,
  PractitionerProfessionalData,
  ResourceName,
  UserWithPractitioner,
} from '@nexuzhealth/shared-domain';
import { Observable } from 'rxjs';
import { map, pluck } from 'rxjs/operators';
import { PersonInformation } from '@nexuzhealth/shared/person/domain';
import { SortOptions, sortOptionsToString } from '@nexuzhealth/shared-util';
import { PractitionerSearchCriteriaModel } from '../model/practitioner-search-criteria.model';
import { PractitionerCreateInfoModel } from '../model/practitioner-create-info.model';

@Injectable({
  providedIn: 'root',
})
export class PractitionerApiService {
  private baseurl = 'api/bas/practitioner/v1';

  constructor(private http: HttpClient) {}

  getPractitioner(name: ResourceName): Observable<Practitioner> {
    const getPractitionerUrl = `${this.baseurl}/${name}`;

    return this.http
      .get<{ practitioner: Practitioner }>(getPractitionerUrl, {
        params: { view: 'ALL_FULL' },
      })
      .pipe(
        map((value: { practitioner: Practitioner }) => {
          return {
            ...value.practitioner,
          };
        })
      );
  }

  addPractitioner(practitioner: Partial<Practitioner>): Observable<Practitioner> {
    const url = `${this.baseurl}/practitioners`;
    return this.http.post<Practitioner>(url, practitioner);
  }

  updatePractitionerPersonData(
    practitionerName: string | null,
    practitionerPersonData: PersonInformation
  ): Observable<PersonInformation> {
    return this.http.patch<PersonInformation>(
      `${this.baseurl}/${practitionerName}:general-information`,
      practitionerPersonData
    );
  }

  updatePractitionerProfessionalData(
    practitionerName: string | null,
    practitionerProfessionalData: PractitionerProfessionalData
  ): Observable<PractitionerProfessionalData> {
    return this.http.patch<PractitionerProfessionalData>(
      `${this.baseurl}/${practitionerName}:professional-information`,
      practitionerProfessionalData
    );
  }

  getHealthCareWorkerProfessions(type: ListType): Observable<HealthCareProfession[]> {
    const url = 'api/bas/reference/v1/healthcareworkerprofessions';
    const params = new HttpParams().append('type', type).append('view', 'WITH_QUALIFICATIONS');

    return this.http.get<{ data: HealthCareProfession[] }>(url, { params: params }).pipe(map((p) => p.data));
  }

  findHealthCareProfessional(data: any, pageToken?: string, pageSize?: number) {
    const url = `api/ehealth/addressbook/v1/professional:search`;
    const params = {
      pageSize,
      pageToken,
      ...data,
    };

    return this.http.post<SearchProfessionalResponse>(url, params).pipe(
      map((resp) => ({
        pageToken: resp.nextPageToken,
        totalSize: resp.totalSize,
        data: resp.professionals,
      }))
    );
  }

  createPractitionerFromProfessional(nihii: string): Observable<Practitioner> {
    const url = `api/ehealth/addressbook/v1/professional/${nihii}:createPractitioner`;
    return this.http.post<{ practitioner: Practitioner }>(url, null).pipe(pluck('practitioner'));
  }

  getPractitionerByAssignedId(type: string, value: string): Observable<Practitioner | null> {
    const url = `${this.baseurl}/practitioners:byAssignedId`;
    const params = { [type]: value };

    return this.http.get<{ practitioner: Practitioner | null }>(url, { params }).pipe(pluck('practitioner'));
  }

  searchPractitionerContexts(
    searchString: string,
    professions: string[],
    limit: number
  ): Observable<PagingResult<PractitionerContext>> {
    const url = `${this.baseurl}/practitioners:search`;

    let params = new HttpParams()
      .append('searchString', searchString)
      .append('order_by', 'familyName')
      .append('pageSize', limit.toString(10));

    if (professions) {
      professions.forEach((prof) => (params = params.append('professionCodes', prof)));
    }

    function mapContexts(ctxs: PractitionerContext[], pra: Practitioner): PractitionerContext[] {
      return ctxs.map((ctx: PractitionerContext) => {
        return { ...ctx, practitioner: pra };
      });
    }

    function mapPractitionerContext(data: Practitioner[]): PractitionerContext[] {
      return data.flatMap((pra) => mapContexts(pra.contexts, pra));
    }

    return this.http.get<PagingResult<Practitioner>>(url, { params }).pipe(
      map((resp) => ({
        pageToken: resp['nextPageToken'],
        totalSize: resp.totalSize,
        data: mapPractitionerContext(resp.data),
      }))
    );
  }

  searchPractitionersForCriteria(
    searchCriteria: PractitionerSearchCriteriaModel,
    options: { pageSize: number; token?: string },
    sortOptions: SortOptions<Practitioner>
  ): Observable<PagingResult<Practitioner>> {
    const url = `${this.baseurl}/practitioners:search`;

    let params = new HttpParams().appendAll({
      pageToken: options.token || '',
      pageSize: options.pageSize,
    });

    if (sortOptions) {
      params = params.append('order_by', sortOptionsToString(sortOptions));
    }

    if (searchCriteria.familyName) {
      params = params.append('familyName', searchCriteria.familyName);
    }
    if (searchCriteria.givenName) {
      params = params.append('givenName', searchCriteria.givenName);
    }
    if (searchCriteria.inss) {
      params = params.append('inss', searchCriteria.inss);
    }
    if (searchCriteria.nihii) {
      params = params.append('nihii', searchCriteria.nihii);
    }

    if (searchCriteria.professionCode) {
      params = params.append('professionCode', searchCriteria.professionCode);
    }

    return this.http.get<PagingResult<Practitioner>>(url, { params }).pipe(
      map((resp) => ({
        pageToken: resp['nextPageToken'],
        data: resp.data,
        totalSize: resp.totalSize,
      }))
    );
  }

  searchPractitioners(
    searchString: string,
    professions: string[],
    limit: number,
    names: string[]
  ): Observable<PagingResult<Practitioner>> {
    const url = `${this.baseurl}/practitioners:search`;

    let params = new HttpParams()
      .append('searchString', searchString)
      .append('order_by', 'familyName')
      .append('pageSize', limit.toString(10));

    if (professions) {
      professions.forEach((prof) => (params = params.append('professionCodes', prof)));
    }
    if (names) {
      names.forEach((name) => (params = params.append('names', name)));
    }

    return this.http.get<PagingResult<Practitioner>>(url, { params }).pipe(
      map((resp) => ({
        pageToken: resp['nextPageToken'],
        totalSize: resp.totalSize,
        data: resp.data,
      }))
    );
  }

  getPractitioners(professions: string[]): Observable<PagingResult<Practitioner>> {
    const url = `${this.baseurl}/practitioners:search`;

    let params = new HttpParams().append('currentEntity', true).append('order_by', 'familyName');

    if (professions) {
      professions.forEach((prof) => (params = params.append('professionCodes', prof)));
    }

    return this.http.get<PagingResult<Practitioner>>(url, { params }).pipe(
      map((resp) => ({
        pageToken: resp['nextPageToken'],
        totalSize: resp.totalSize,
        data: resp.data,
      }))
    );
  }

  searchUsersWithPractitioners(
    searchString: string,
    entityName?: string,
    iamAccessRoleId?: string
  ): Observable<UserWithPractitioner[]> {
    const url = `api/iam/identity/v1/users:with_practitioners`;
    let params = new HttpParams();
    if (searchString) {
      params = params.append('searchString', searchString);
    }
    if (entityName) {
      params = params.append('entityName', entityName);
    }
    if (iamAccessRoleId) {
      params = params.append('roleId', iamAccessRoleId);
    }
    return this.http
      .get<Record<'data', UserWithPractitioner[]>>(url, {
        params: params,
      })
      .pipe(pluck('data'));
  }

  getPractitionerCreateInformation(nihii: string): Observable<PractitionerCreateInfoModel> {
    const params = new HttpParams().append('nihii', nihii);

    const url = `${this.baseurl}/practitioners:create-information`;
    return this.http.get<PractitionerCreateInfoModel>(url, { params });
  }
}

export interface SearchProfessionalResponse {
  professionals: Professional[];
  totalSize: number;
  nextPageToken: string;
}

export interface Professional {
  givenName: string;
  familyName: string;
  middleName: string;
  gender: string;
  birthDate: string;
  deathDate: string;
  professions: Profession[];
  addresses: Record<string, Address>;
  additionalInformation: Record<string, string>;
  inss: string;
  nihii: string;
  language: string;
  email: string;
}

export interface Profession {
  professionCodes: ProfessionCode[];
  specialityCodes: string[];
  addresses: Record<string, Address>;
  nihii: string;
  ehealthBoxes: string;
  additionalInformation: string;
}

export interface ProfessionCode {
  code: string;
  source: string;
  type: string;
}
