import { ChangeDetectorRef, Pipe, PipeTransform } from '@angular/core';
import { filterNilValue } from '@datorama/akita';
import { Address } from '@nexuzhealth/shared-domain';
import { CityQuery, CountryQuery, ReferenceQuery } from '@nexuzhealth/shared-reference-data-data-access';
import { map, take } from 'rxjs/operators';

@Pipe({
  name: 'formatAddress',
  pure: false,
})
export class FormatAddressPipe implements PipeTransform {
  constructor(private _ref: ChangeDetectorRef, private countryQuery: CountryQuery, private cityQuery: CityQuery) {}

  private queryReference(query: ReferenceQuery, value: string) {
    return query.selectEntity(value).pipe(
      filterNilValue(),
      map((c) => c.value),
      take(1)
    );
  }

  transform(address?: Address): string {
    if (!address) {
      return '';
    }

    let country: string | undefined = '';
    let city = '';
    if (address.country) {
      const entity = this.countryQuery.getEntity(address.country);
      if (entity) {
        country = entity.value;
      } else {
        this.queryReference(this.countryQuery, address.country).subscribe((c) => {
          this._ref.markForCheck();
        });
      }
    }

    if (address.city) {
      if (address.city.indexOf('cities/') === 0) {
        const entity = this.cityQuery.getEntity(address.city)?.value;
        if (entity) city = entity;
        else {
          this.queryReference(this.cityQuery, address.city).subscribe((c) => {
            this._ref.markForCheck();
          });
        }
      } else {
        city = address.city;
      }
    }

    const resultStreet = [address.street, address.number, address.box].filter(this.valueNotEmpty).join(' ');
    const resultCity = [address.postalCode, city].filter(this.valueNotEmpty).join(' ');
    return [resultStreet, resultCity, country].filter(this.valueNotEmpty).join(', ');
  }

  private valueNotEmpty(value?: string): boolean {
    if (typeof value !== 'string') {
      return false;
    }
    return value.length > 0;
  }
}
