import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  forwardRef,
  Input,
  OnInit,
  Optional,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import { addDataTestAttributes, DataTestDirective } from '@nexuzhealth/shared-tech-feature-e2e';
import { NgSelectComponent } from '@ng-select/ng-select';
import { CountryCode, parsePhoneNumberFromString as parseMin, PhoneNumber } from 'libphonenumber-js';
import { getCountries, parsePhoneNumberFromString as parseMobile } from 'libphonenumber-js/mobile';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'nxh-phone-number',
  templateUrl: './phone-number.component.html',
  styleUrls: ['./phone-number.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PhoneNumberComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => PhoneNumberComponent),
      multi: true,
    },
  ],
})
export class PhoneNumberComponent implements OnInit, ControlValueAccessor, Validator {
  @Input() mobile = false;
  @Input() countryCode: CountryCode = 'BE';
  @Input() placeholder = '';
  @ViewChild('input', { static: true }) inputField: ElementRef<HTMLInputElement>;
  @ViewChild('select', { static: true }) countrySelect: NgSelectComponent;

  formattedPhonenumber: string;
  readonly disabled = new BehaviorSubject(false);
  readonly countries = getCountries();
  onChange: (tel: string) => void;
  onTouch: () => void;

  constructor(private cdr: ChangeDetectorRef, @Optional() private dataTestDirective?: DataTestDirective) {}

  ngOnInit(): void {
    addDataTestAttributes(
      this.dataTestDirective?.nxhDataTest ? `${this.dataTestDirective.nxhDataTest}_phonenumber-` : 'phonenumber-',
      {
        element: this.inputField.nativeElement,
        suffix: 'input',
      },
      {
        element: this.countrySelect.element,
        suffix: 'select-country',
      }
    );
  }

  registerOnChange(onChange: (tel: string) => void): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouch: () => void): void {
    this.onTouch = onTouch;
  }

  setDisabledState(disabled: boolean): void {
    this.disabled.next(disabled);
  }

  writeValue(phonenumber: string): void {
    if (phonenumber) {
      const parsedPhonenumber: PhoneNumber = this.parsePhoneNumber(phonenumber);
      this.formattedPhonenumber = parsedPhonenumber.formatInternational() || phonenumber;
      this.countryCode = parsedPhonenumber?.country;
    } else {
      this.formattedPhonenumber = null;
      this.countryCode = 'BE';
    }
    this.cdr.markForCheck();
  }

  validate(control: AbstractControl): ValidationErrors | null {
    if (!control.value) {
      return null;
    }

    // minimal validation, no distinction between mobile and fixed
    const phoneNumber = parseMin(control.value, this.countryCode);
    return phoneNumber?.isValid() ? null : { 'invalid-phone': true };
  }

  onSelectCountryCode(countryCode: CountryCode) {
    this.countryCode = countryCode;

    // reset phonenumber
    this.onPhonenumberChange(null);
    this.onTouch();

    this.inputField.nativeElement.focus();
  }

  onPhonenumberChange(phonenumber: string) {
    if (phonenumber) {
      const parsedPhonenumber: PhoneNumber = this.parsePhoneNumber(phonenumber);
      this.formattedPhonenumber = parsedPhonenumber?.formatInternational() || phonenumber;
      this.onChange(withoutSpaces(this.formattedPhonenumber));
    } else {
      this.formattedPhonenumber = undefined;
      this.onChange(undefined);
    }
  }

  private parsePhoneNumber(text: string) {
    return this.mobile ? parseMobile(text, this.countryCode) : parseMin(text, this.countryCode);
  }
}

function withoutSpaces(phonenumber?: string) {
  return phonenumber?.replace(/\s/g, '');
}
