import { Component, OnInit } from '@angular/core';
import { ScrollspyService } from '@nexuzhealth/shared-ui-toolkit/scrollspy';
import { BubbleRef, DetailBubbleService } from '@nexuzhealth/shared-ui-toolkit/detail';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { expand, map, scan, take } from 'rxjs/operators';
import { DetailBubbleDemoContentComponent } from './detail-bubble-demo-content.component';

@Component({
  selector: 'nxh-detail-bubble-demo',
  templateUrl: './detail-bubble-demo.component.html',
  styleUrls: ['./detail-bubble-demo.component.scss'],
})
export class DetailBubbleDemoComponent implements OnInit {
  contentCode = `
    <div class="bubble-content">
      <div class="bubble-content__row">
        <div class="bubble-content__col">
          <fa-icon class="bubble-content__icon" icon="pen"></fa-icon>
        </div>
        <div class="bubble-content__col">
          geen contacten
        </div>
      </div>

      <div class="card-body card-body--striped">
        <div class="card-body__container card-body__container--has-icon">
          <fa-icon class="bubble-content__icon" icon="pen"></fa-icon>
          <div class="card-body__container__content">
            <div class="data-list">
              <div class="data-list__row">
                <div class="data-list__key">Status</div>
                <div class="data-list__value">Passief relevant</div>
              </div>
              <div class="data-list__row">
                <div class="data-list__key">Count</div>
                <div class="data-list__value">{{ count }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  `;

  contentCodeMax = `
<div class="bubble-content__key-value-item">
  <div class="key">Count</div>
  <div class="val val--max-height">{{ count }}</div>
</div>`;

  data$: Observable<{ id: number; lang: string }[]>;
  count$: Observable<number>;
  activeId = '';
  displayedColumns = ['id', 'lang'];
  DetailBubbleDemoContentComponent = DetailBubbleDemoContentComponent;
  private countSubject = new BehaviorSubject(0);

  constructor(private bubble: DetailBubbleService, private scrollspyService: ScrollspyService) {}

  showExampleBlock1 = true;
  block1Code = `
  <tr
    nxhDetailBubble
    #bubble="detailBubble"
    (click)="bubble.show($event, templ)"
    [title]="'demo time'"
    [actions]="[
      { actionName: 'delete', icon: 'trash-alt', confirm: true, status: 'danger' },
      { actionName: 'edit', icon: 'pen' },
      { routerLink: 'typography', icon: 'link' }
    ]"
    (action)="doAction($event)"
  ></tr>

  <ng-template #templ> <nxh-detail-popover-target-demo></nxh-detail-popover-target-demo> </ng-template>
  `;

  showExampleBlock2 = true;
  block2Code = `
  <tr
    (click)="activeId = row.id; bubble.open($event, DetailBubbleDemoContentComponent)"
    nxhDetailBubble
    #bubble="detailBubble"
    [title]="'demo time'"
    [actions]="[{ actionName: 'delete', icon: 'trash-alt', status: 'danger' }, { actionName: 'edit', icon: 'pen' }]"
    (action)="doAction($event)"
  ></tr>

  export class DetailBubbleDemoComponent implements OnInit {
    //...
    DetailBubbleDemoContentComponent = DetailBubbleDemoContentComponent;
    //...
  }
  `;

  showExampleBlock3 = true;
  block3Code = `
  <tr
    (click)="showBubble($event, row)"
  ></tr>

  export class DetailBubbleDemoComponent implements OnInit {

    constructor(private bubble: DetailBubbleService) {}

    showBubble($event, programmingLanguage) {
      this.activeId = programmingLanguage;
      // typically the ProgrammingLanguage would be put as active in the store
      // and the DetailBubbleDemoContentComponent would access that ProgrammingLanguage
      // via a Query
      const ref = this.bubble.show(DetailBubbleDemoContentComponent, $event.srcElement.parentElement, {
        title: 'demo',
        actions: [{ icon: 'pen', actionName: 'edit' }]
      });
      ref.closed$.subscribe({
        next: val => alert(val)
      });
    }
  }
  `;

  showExampleBlock4 = true;
  block4Code = `
  // module
  @NgModule({
    entryComponents: [DetailBubbleDemoContentComponent],
    providers: [DetailBubbleService, PopoverService]
  })

  // template
  <button class="btn btn-primary" (click)="showBubble($event)">
    <fa-icon icon="user"></fa-icon> <div>Click me please</div>
  </button>

  // component (parent)
  DetailBubbleDemoContentComponent = DetailBubbleDemoContentComponent;

  constructor(private bubble: DetailBubbleService) {}

  showBubble($event: MouseEvent) {
    const ref = this.bubble.show(DetailBubbleDemoContentComponent, $event.target['parentElement'], {
      title: 'demo',
      actions: [{ icon: 'pen', actionName: 'edit', confirm: true }],
    });
    ref.closed$.subscribe({
      next: (actionName) => this.doAction(actionName, ref),
    });
  }

  // add a detail component to show into the detail bubble (DetailBubbleDemoContentComponent)
  `;

  selected$ = new BehaviorSubject('block4');

  scrollTo(id) {
    this.selected$.next(id);
    this.scrollspyService.scrollTo(id, { emitEvent: false });
  }

  scrolledInView(id) {
    this.selected$.next(id);
  }

  ngOnInit() {
    this.data$ = of(['java', 'go', 'typescript']).pipe(
      expand((arr) => of(arr)),
      take(10),
      scan((acc, val) => {
        return [...acc, ...val];
      }, []),
      map((items) => items.map((item, idx) => ({ lang: item, id: idx })))
    );

    this.count$ = this.countSubject.asObservable();
  }

  addCount() {
    this.countSubject.next(this.countSubject.getValue() + 1);
  }

  showBubble($event: MouseEvent, programmingLanguage?) {
    // typically the ProgrammingLanguage would be put as active in the store
    // and the DetailBubbleDemoContentComponent would access that ProgrammingLanguage
    // via a Query
    this.activeId = programmingLanguage?.id;
    // note that we set the tr as origin ("$event.srcElement.parentElement"), not the srcElement (the default)
    // this is an advantage of calling the Bubble programmatically
    const ref = this.bubble.show(DetailBubbleDemoContentComponent, $event.target['parentElement'], {
      title: 'demo',
      size: 'sm',
      actions: [{ icon: 'pen', actionName: 'edit', confirm: true }],
    });
    ref.result$.subscribe({
      next: (actionName) => this.doAction(actionName, ref),
    });
  }

  doAction($event, ref: BubbleRef) {
    setTimeout(() => {
      console.log($event);
      ref.close();
    }, 1000);
  }
}
