import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  HostBinding,
  inject,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { PersonName, ResourceName } from '@nexuzhealth/shared-domain';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { BLOB_SERVICE_TOKEN } from '@nexuzhealth/shared-tech-util';
import { formatPersonName } from '@nexuzhealth/shared-util';
import { AvatarFullNameDirective } from './avatar-full-name.directive';
import { AvatarNoFullNameDirective } from './avatar-no-full-name.directive';

@Component({
  selector: 'nxh-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AvatarComponent implements OnInit, OnChanges {
  @Input() fullName?: string | PersonName;

  /**
   * ResourceName specifying the person's image
   */
  @Input() pictureName?: ResourceName;
  @Input() size: 'xs' | 'sm' | 'md' | 'lg' | 'xl' = 'md';

  /**
   * Specifies whether the fullname should be displayed
   */
  @Input() showFullNameText = false;

  /**
   * Text to show in case no fullName is available. Only relevant if showFullNameText is true
   */
  @Input() noFullNameText?: string;

  protected pictureImg$!: Observable<string | null>;
  protected imgNotLoaded: boolean | null = null;
  private cdr = inject(ChangeDetectorRef);
  private dataLookup? = inject(BLOB_SERVICE_TOKEN, { optional: true });
  private pictureName$$ = new BehaviorSubject<ResourceName | null | undefined>(null);

  @ContentChild(AvatarFullNameDirective) fullnameRef?: AvatarFullNameDirective;
  @ContentChild(AvatarNoFullNameDirective) noFullnameRef?: AvatarNoFullNameDirective;

  @HostBinding('class.xs') get xs() {
    return this.size === 'xs';
  }

  @HostBinding('class.sm') get sm() {
    return this.size === 'sm';
  }

  @HostBinding('class.md') get md() {
    return this.size === 'md';
  }

  @HostBinding('class.lg') get lg() {
    return this.size === 'lg';
  }

  @HostBinding('class.xl') get xl() {
    return this.size === 'xl';
  }

  get fullNameVisible() {
    return this.showFullNameText || this.fullnameRef !== undefined;
  }

  get formattedFullName() {
    if (!this.fullName) {
      return null;
    }
    return typeof this.fullName === 'string' ? this.fullName : formatPersonName(this.fullName);
  }

  ngOnInit(): void {
    if (this.dataLookup) {
      this.pictureImg$ = this.pictureName$$.pipe(
        switchMap((pictureName) => {
          return pictureName ? this.dataLookup.loadAsDataUrl(pictureName, true) : of(null);
        })
      );
    } else {
      this.pictureImg$ = of(null);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    const change = changes['pictureName'];
    if (change) {
      this.pictureName$$.next(this.pictureName);
    }
  }

  onImgNotLoaded() {
    this.imgNotLoaded = true;
    this.cdr.markForCheck();
  }
}
