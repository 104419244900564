import { isRecurrenceTimingTimeslot, WeekDayRecurrence, WeekRecurrence } from '@nexuzhealth/shared-domain';
import { WeekDayRecurrenceFormValue, WeekRecurrenceFormValue } from '../recurrences-form.domain';

export function mapToWeekRecurrence(
  weekRecurrenceFormValue: WeekRecurrenceFormValue,
  useDayPartAllDay: boolean
): WeekRecurrence {
  const week: WeekRecurrence['week'] = {};

  if (weekRecurrenceFormValue.week._selectFrequencyOrWeekday === 'frequency') {
    week.frequency = weekRecurrenceFormValue.week.frequency;
  } else {
    week.days = weekRecurrenceFormValue.week.days?.map((day) => mapToWeekDayRecurrence(day, useDayPartAllDay));
  }

  const result: WeekRecurrence = {
    period: weekRecurrenceFormValue.period,
    week,
    timings: weekRecurrenceFormValue.week.timings,
    time: weekRecurrenceFormValue.week.dayparts?.time,
  };

  if (weekRecurrenceFormValue.week.dayparts) {
    result.timings = [];
    // Is allDay recurrence or Time = no recurrence
    if (weekRecurrenceFormValue.week.dayparts.allDayOrTime) {
      if (useDayPartAllDay) {
        result.timings.push({ allDay: true });
      }
    } else {
      // Is day part
      weekRecurrenceFormValue.week.dayparts.parts?.forEach((part) => {
        result.timings.push({ dayPartName: part });
      });
    }
  }

  return result;
}

function mapToWeekDayRecurrence(
  weekDayRecurrenceFormValue: WeekDayRecurrenceFormValue,
  useDayPartAllDay: boolean
): WeekDayRecurrence {
  const timings = [];
  if (weekDayRecurrenceFormValue.timings) {
    weekDayRecurrenceFormValue?.timings.forEach((timing) => {
      if (isRecurrenceTimingTimeslot(timing)) {
        if (timing.timeslot.start && timing.timeslot.end) {
          timings.push(timing);
        }
      } else {
        timings.push(timing);
      }
    });
  }

  if (weekDayRecurrenceFormValue.dayparts) {
    // Is allDay recurrence or Time = no recurrence
    if (weekDayRecurrenceFormValue.dayparts.allDayOrTime) {
      if (useDayPartAllDay) {
        timings.push({ allDay: true });
      }
    } else {
      // Is day part
      weekDayRecurrenceFormValue.dayparts.parts?.forEach((part) => {
        timings.push({ dayPartName: part });
      });
    }
  }

  return { weekday: weekDayRecurrenceFormValue.weekday, timings, time: weekDayRecurrenceFormValue.dayparts?.time };
}
