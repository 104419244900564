import {
  ComponentFactoryResolver,
  ComponentRef,
  Directive,
  ElementRef,
  Injector,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional,
  Self,
  SimpleChanges,
  ViewContainerRef,
} from '@angular/core';
import { CompactFormControlDirective } from '@nexuzhealth/shared-ui-toolkit/compact-form-control';
import { ErrorMessageComponent, ErrorMessageConfigToken } from '../components/error-message/error-message.component';
import { AutoValidateDirective } from './auto-validate.directive';
import { BypassAutoValidateDirective } from './bypass-auto-validate.directive';

const defaultErrorMap = { required: 'required%label' };

/**
 * @deprecated Use val-errors
 */
// cf. inspired by: https://netbasal.com/make-your-angular-forms-error-messages-magically-appear-1e32350b7fa5
@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[formControlName], [formControl]',
})
export class ControlError2Directive implements OnInit, OnDestroy, OnChanges {
  /**
   *   This field allows for setting the prefix of error messages. When empty the default prefix 'common:_errors.' is
   *   used.
   *
   *   For example, considering <input errorLabelPrefix="_contact-family" required>, the translate key changes
   *   to "_contact-family._errors.required".
   */
  @Input() errorLabelPrefix: string;

  /**
   * Added for backwardcompatibility reasons. When there is an error (any error), this is the message that will be
   * displayed.
   */
  @Input() nxhControlError: string;

  private _label;

  // eslint-disable-next-line
  @Input() set label(label: string) {
    if (label) {
      this._label = label;
    }
  }
  get label() {
    return this._label;
  }

  @Input() set nxhCompactFormControl(label: string) {
    if (label) {
      this._label = label;
    }
  }

  @Input() set nxhLabel(label: string) {
    if (label) {
      this._label = label;
    }
  }

  @Input() errorMap: { [errorName: string]: string } = {};

  @Input() errorContext: { [name: string]: string } = {};

  private ref: ComponentRef<ErrorMessageComponent>;

  constructor(
    @Optional() private autoValidateDirective: AutoValidateDirective,
    @Optional() private bypassAutoValidateDirective: BypassAutoValidateDirective,
    private resolver: ComponentFactoryResolver,
    private _vcr: ViewContainerRef,
    private injector: Injector,
    @Self() private elementRef: ElementRef,
    @Self() @Optional() private compactFormControlDirective: CompactFormControlDirective
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.autoValidateDirective || this.bypassAutoValidateDirective) {
      return;
    }

    if (changes.nxhLabel && !changes.nxhLabel.firstChange) {
      this.ref.instance.config.errorContext = { label: changes.nxhLabel.currentValue };
    }
  }

  ngOnInit(): void {
    if (!this.autoValidateDirective || this.bypassAutoValidateDirective) {
      return;
    }

    const factory = this.resolver.resolveComponentFactory(ErrorMessageComponent);
    const _injector = Injector.create({
      providers: [
        {
          provide: ErrorMessageConfigToken,
          useValue: {
            prefix: this.errorLabelPrefix,
            errorMap: { ...defaultErrorMap, ...this.errorMap },
            errorContext: { label: this.label, ...this.errorContext },
            customErrorMessage: this.nxhControlError,
            hasCompactFormControlDirective: !!this.compactFormControlDirective,
            sourceElementRef: this.elementRef,
          },
        },
      ],
      parent: this.injector,
    });
    this.ref = this._vcr.createComponent(factory, undefined, _injector);
  }

  ngOnDestroy(): void {
    if (this.ref) {
      this.ref.destroy();
    }
  }
}
