import { Injectable } from '@angular/core';
import { applyTransaction } from '@datorama/akita';
import { of, throwError } from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';
import { ResourceName } from '@nexuzhealth/shared-domain';
import { FeatureFlagApiService } from '../api/feature-flag-api.service';
import { FeatureFlagQuery } from './feature-flag.query';
import { FeatureFlagStore } from './feature-flag.store';

@Injectable({ providedIn: 'root' })
export class FeatureFlagService {
  constructor(
    private featureFlagStore: FeatureFlagStore,
    private featureFlagApi: FeatureFlagApiService,
    private featureFlagQuery: FeatureFlagQuery
  ) {}

  load(featureFlagName: ResourceName) {
    if (this.featureFlagQuery.hasEntity(featureFlagName)) {
      return of(null);
    }

    this.featureFlagStore.add({ name: featureFlagName, enabled: false, loading: true });
    return this.featureFlagApi.load(featureFlagName).pipe(
      tap((featureFlag) => {
        this.featureFlagStore.upsert(featureFlagName, { ...featureFlag, loading: false });
      }),
      catchError((err) => {
        this.featureFlagStore.update(featureFlagName, { loading: false });
        console.error('error while fetching feature flag', featureFlagName);
        return throwError(err);
      }),
      finalize(() => {
        if (this.featureFlagQuery.getEntity(featureFlagName).loading === true) {
          // removing featureflag because request was cancelled before we got a response
          this.featureFlagStore.remove(featureFlagName);
        }
      })
    );
  }

  /**
   * Loads the given feature flags. Returns the feature flags that have been newly loaded. Feature flags that had been
   * loaded previously are not returned. Use FeatureFlagQuery to check the status of the various feature flags.
   */
  loadMany(...featureFlagNames: ResourceName[]) {
    const toLoadFeatureFlagNames = [];
    featureFlagNames.forEach((featureFlagName) => {
      if (!this.featureFlagQuery.hasEntity(featureFlagName)) {
        this.featureFlagStore.add({ name: featureFlagName, enabled: false, loading: true });
        toLoadFeatureFlagNames.push(featureFlagName);
      }
    });

    if (toLoadFeatureFlagNames.length === 0) {
      return of([]);
    }

    return this.featureFlagApi.loadMany(toLoadFeatureFlagNames).pipe(
      tap((featureFlags) => {
        applyTransaction(() => {
          featureFlags.forEach((featureFlag) =>
            this.featureFlagStore.upsert(featureFlag.name, { ...featureFlag, loading: false })
          );
        });
      }),
      catchError((err) => {
        toLoadFeatureFlagNames.forEach((flagName) => {
          this.featureFlagStore.update(flagName, { loading: false });
        });
        console.error('error while fetching feature flags', featureFlagNames);
        return throwError(err);
      })
    );
  }
}
