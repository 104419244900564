import {Directive, ElementRef, Input, TemplateRef, ViewContainerRef} from '@angular/core';
import {AuthService} from '@nexuzhealth/shared/authentication/data-access-auth';
import {RoleExpr} from '@nexuzhealth/shared-domain';

/**
 * Structural directive that renders its content when the user has one of the given AccessRole.
 *
 * Inspired by: https://juristr.com/blog/2018/02/angular-permission-directive/
 */
@Directive({
  // eslint-disable-next-line
  selector: '[isAuthorized]',
})
export class IsAuthorizedDirective {
  @Input() set isAuthorized(roleExpr: RoleExpr) {
    console.assert(!!roleExpr, 'Using isAuthorized directive without providing a RoleExpr. Programming error?');
    this.updateView(roleExpr);
  }

  constructor(
    private el: ElementRef,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private authService: AuthService
  ) {}

  private updateView(roleExpr: RoleExpr) {
    if (this.authService.isAuthorized(roleExpr)) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }
}
