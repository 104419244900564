import { Component, ElementRef, HostBinding, Input, Optional, ViewChild } from '@angular/core';
import { guid } from '@datorama/akita';
import { Focusable } from '@nexuzhealth/shared-ui-toolkit/focus';
import { RadioGroupComponent } from '../radio-group/radio-group.component';
import { RadioGroupService } from '../radio-group/radio-group.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'nh-radio',
  templateUrl: './radio.component.html',
  styleUrls: ['./radio.component.scss'],
})
export class RadioComponent implements Focusable {
  @Input() label!: string;
  @Input() id = guid();
  @Input() value!: any;
  @Input() noLabel = false;
  @Input() disabled = false;

  @ViewChild('input', { static: false }) input!: ElementRef<HTMLInputElement>;

  @HostBinding('class.margin-bottom-enabled')
  @Input()
  public hasBottomMargin = true;

  constructor(public radioGroupService: RadioGroupService, @Optional() private parent?: RadioGroupComponent) {}

  get formControl() {
    return this.radioGroupService.parent;
  }

  get name() {
    return this.radioGroupService.name;
  }

  setFocus() {
    this.input.nativeElement.focus();
  }

  onBlur() {
    this.parent?.onRadioButtonBlur();
  }
}
